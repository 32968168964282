import React from 'react';
import { Helmet } from 'react-helmet';
import { useLanguageContext } from '../../contexts/language-context';
import { SchemaTypes, Translation } from '@reservation-app/common-used-in-web';
import { sanitizeUrlSlashes } from '../../helpers/sanitize-url-slashes';

interface SeoProps {
  title: Translation;
  description?: Translation;
  keywords?: Translation;
  siteUrl: string;
  pageUrl: Translation;
  image?: { url: string };
  companySeo: SchemaTypes['Company']['seo'];
}

export const Seo = ({
  title,
  description,
  siteUrl,
  pageUrl,
  image,
  companySeo,
}: SeoProps) => {
  const { activeLanguage, languages, defaultLanguage } = useLanguageContext();

  // OPTIONAL-TODO: add JSON-schema SEO

  const isDefaultLanguage = activeLanguage === defaultLanguage;

  const isHomePage = pageUrl.en === '/';

  const currentLanguagePrefix = isDefaultLanguage ? '/' : `/${activeLanguage}/`;

  return (
    <Helmet>
      {(title?.[activeLanguage] || companySeo.title?.[activeLanguage]) && [
        <title key="title">
          {title?.[activeLanguage] || companySeo.title?.[activeLanguage]}
        </title>,
        <meta
          key="og:title"
          property="og:title"
          content={
            title?.[activeLanguage] || companySeo.title?.[activeLanguage]
          }
        />,
        <meta
          key="twitter:title"
          property="twitter:title"
          content={
            title?.[activeLanguage] || companySeo.title?.[activeLanguage]
          }
        />,
      ]}
      {description?.[activeLanguage] && [
        <meta
          key="description"
          name="description"
          content={description[activeLanguage]}
        />,
        <meta
          key="og:description"
          property="og:description"
          content={description[activeLanguage]}
        />,
        <meta
          key="twitter:description"
          property="twitter:description"
          content={description[activeLanguage]}
        />,
      ]}
      {/* This does not do anything */}
      {/* {keywords?.[activeLanguage] && (
        <meta name="keywords" content={keywords[activeLanguage]} />
      )} */}
      {/* OPTIONAL-TODO: add twitter images separately because of the different ratio */}
      {(image ||
        companySeo.favicon512x ||
        companySeo.favicon192x ||
        companySeo.favicon16x ||
        companySeo.favicon) && [
        <meta
          key="image"
          name="image"
          content={
            image?.url ||
            companySeo.favicon512x?.url ||
            companySeo.favicon192x?.url ||
            companySeo.favicon16x?.url ||
            companySeo.favicon?.url
          }
        />,
        <meta
          key="og:image"
          property="og:image"
          content={
            image?.url ||
            companySeo.favicon512x?.url ||
            companySeo.favicon192x?.url ||
            companySeo.favicon16x?.url ||
            companySeo.favicon?.url
          }
        />,
        <meta
          key="twitter:image"
          property="twitter:image"
          content={
            image?.url ||
            companySeo.favicon512x?.url ||
            companySeo.favicon192x?.url ||
            companySeo.favicon16x?.url ||
            companySeo.favicon?.url
          }
        />,
        <meta
          key="twitter:card"
          property="twitter:card"
          content={
            image?.url ||
            companySeo.favicon512x?.url ||
            companySeo.favicon192x?.url ||
            companySeo.favicon16x?.url ||
            companySeo.favicon?.url
          }
        />,
      ]}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={sanitizeUrlSlashes(
          `${siteUrl}${currentLanguagePrefix}${pageUrl[activeLanguage]}`,
          {
            ensureSlashAtTheEnd: isHomePage,
            shouldRemoveLastCharIfSlash:
              isDefaultLanguage || (!isDefaultLanguage && !isHomePage),
          },
        )}
      />
      <meta property="og:locale" content={activeLanguage} />
      <link
        rel="canonical"
        href={sanitizeUrlSlashes(
          `${siteUrl}${currentLanguagePrefix}${pageUrl[activeLanguage]}`,
          {
            ensureSlashAtTheEnd: isHomePage,
            shouldRemoveLastCharIfSlash:
              isDefaultLanguage || (!isDefaultLanguage && !isHomePage),
          },
        )}
      />
      {/* Since we do not redirect at this time we disable this header */}
      {/* {languages.length > 1 && isHomePage && (
        <link
          rel="alternate"
          href={sanitizeUrlSlashes(siteUrl, { ensureSlashAtTheEnd: true })}
          hrefLang="x-default"
        />
      )} */}
      {languages.length > 1 &&
        languages.map((language) => {
          const isDefault = language === defaultLanguage;

          const languagePrefix = isDefault ? '/' : `/${language}/`;

          const alternateLink = (
            <link
              key={`${siteUrl}${languagePrefix}${pageUrl[language]}`}
              rel="alternate"
              href={sanitizeUrlSlashes(
                `${siteUrl}${languagePrefix}${pageUrl[language]}`,
                {
                  ensureSlashAtTheEnd: isHomePage,
                  shouldRemoveLastCharIfSlash:
                    isDefaultLanguage || (!isDefaultLanguage && !isHomePage),
                },
              )}
              hrefLang={language}
            />
          );

          return alternateLink;
        })}
    </Helmet>
  );
};

export default Seo;

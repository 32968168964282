import React, { useLayoutEffect } from 'react';
import {
  ReservationFlow,
  LoadingPage,
  useToast,
} from '@reservation-app/common';
import {
  useIsWindowFocused,
  ErrorPage,
  useTranslationsStore,
  Languages,
} from '@reservation-app/common-used-in-web';
import { useLanguageContext } from '../contexts/language-context';
import HeaderWrapper from './layout/header-wrapper';
import { companyApiClient } from '../helpers/company-api-client';
import { sanitizeUrlSlashes } from '../helpers/sanitize-url-slashes';
import Seo from './layout/seo';
import { navigate } from 'gatsby';
import { LanguageAwareTermsAndConditions } from './terms-and-conditions-page';
import { usePageContext } from '../contexts/page-context';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '../helpers/query-client';

const MakeAReservationPage = () => {
  const pageContext = usePageContext();
  const { activeLanguage, translations, defaultLanguage } =
    useLanguageContext();
  const { setTranslations } = useTranslationsStore();

  useLayoutEffect(() => {
    setTranslations(pageContext.translations);
  }, []);

  const toastManager = useToast();

  const onLeaveReservationFlowClick = (location: string) => {
    const isDefaultLanguage = activeLanguage === defaultLanguage;

    navigate(
      sanitizeUrlSlashes(
        `${
          isDefaultLanguage
            ? location || '/'
            : `/${activeLanguage}/${location || '/'}`
        }`,
      ),
    );
  };

  const seoTextPart = {
    en: pageContext.isVerifyAvailabilityMode
      ? 'Find available period'
      : 'Reservation',
    hu: pageContext.isVerifyAvailabilityMode
      ? 'Szabad időpont keresés'
      : 'Foglalás',
    ro: pageContext.isVerifyAvailabilityMode
      ? 'Căutare perioadă liberă'
      : 'Rezervare',
  };

  const seoTitle = {
    en: `${
      pageContext.companySeo?.title?.en
        ? `${pageContext.companySeo.title.en} | `
        : ''
    }${seoTextPart.en}`,
    hu: `${
      pageContext.companySeo?.title?.hu
        ? `${pageContext.companySeo.title.hu} | `
        : ''
    }${seoTextPart.hu}`,
    ro: `${
      pageContext.companySeo?.title?.ro
        ? `${pageContext.companySeo.title.ro} | `
        : ''
    }${seoTextPart.ro}`,
  };
  return (
    <QueryClientProvider client={queryClient} contextSharing>
      <Seo
        title={seoTitle}
        companySeo={pageContext.companySeo}
        siteUrl={pageContext.siteUrl}
        pageUrl={pageContext.pageUrl}
      />
      <div className="bg-gray-50 custom-container mx-auto pt-12 pb-16 w-full min-h-screen">
        <ReservationFlow
          staticProps={{
            // @ts-ignore: monorepo resolution error from multiple axios versions
            apiClient: companyApiClient,
            defaultLanguage: defaultLanguage as Languages,
            isAdminPanel: false,
            isVerifyAvailabilityMode: pageContext.isVerifyAvailabilityMode,
            useWindowFocus: useIsWindowFocused,
            toastManager,
            onLeaveReservationFlowClick,
            rulesHtml: pageContext.rulesHtml,
            pricesSectionProps: {
              activeLanguage,
              alerts: pageContext.alerts,
              promotions: pageContext.promotions,
              section: pageContext.pricesSection,
              shouldDisableAnimation: true,
            },
            schedulesSectionProps: {
              activeLanguage,
              translations,
              alerts: pageContext.alerts,
              schedules: pageContext.schedules,
              section: pageContext.schedulesSection,
              shouldDisableAnimation: true,
            },
            TermsAndConditionsComponent: LanguageAwareTermsAndConditions,
            pageUrl: pageContext.pageUrl,
            components: {
              error: ErrorPage,
              loading: LoadingPage,
              headerWrapper: HeaderWrapper,
            },
            componentProps: {
              headerWrapper: {
                onLeaveReservationFlowClick,
                headerScrollTriggered: true,
                headerConfig: pageContext.headerConfig,
                pageUrl: pageContext.pageUrl,
              },
            },
          }}
        />
      </div>
    </QueryClientProvider>
  );
};

export default MakeAReservationPage;
